import React, { useState, useEffect } from 'react'
import { PageHeader, Seo } from '../../components'
import DataGrid from '../../components/DataGrid'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { QServiceReports } from '../../services/q-rervices-reporting'
import { getColumns } from '../../components/DataGrid/reportingConfig/executive-timeclock'

const ExecutiveTimeclock = () => {
  const { qUser } = useAuthContext()
  const {
    prismicData: { prismicReports },
  } = usePrismic()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState('')

  const dateEnd = new Date()
  const dateStartOfPrevMonth = new Date(
    dateEnd.getFullYear(),
    dateEnd.getMonth() - 1,
    1
  )

  const input = {
    legacyAssociateId: qUser?.legacyAssociateId,
    dateStart: dateStartOfPrevMonth,
    dateEnd: dateEnd,
    associateTypes: ['AMBASSADOR'],
    associateStatuses: [],
    filterTypes: ['SIGNUP_DATE'],
    countryCodes: [],
  }

  const columns = getColumns(prismicReports)

  const getExecutiveTimeClockReport = async () => {
    setLoading(true)
    await QServiceReports.Reports.getExecutiveTimeClockReport({ input })
      .then(response => setData(response))
      .catch(err => setError(err))
    setLoading(false)
  }

  useEffect(() => {
    if (qUser) {
      getExecutiveTimeClockReport()
    }
  }, [qUser])

  return (
    <>
      <Seo title={prismicReports.exec_timeclock} />
      <PageHeader exitRoute="/reports">
        {prismicReports.exec_timeclock}
      </PageHeader>
      <DataGrid columns={columns} data={data} loading={loading} error={error} />
    </>
  )
}

export default ExecutiveTimeclock
